import React, { Component } from "react";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import monthsList from "../../static/months";
import dayList from "../../static/days";
import signUpState from "../../static/clearSignUpState";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/fieldset.css";
import toTitleconverttoTitle from "../../util/toTitleConveter";
import hashPasscode from "../../util/hashPasscode";
import tzPhoneNumberValidator from "../../util/tzPhoneNumberValidator";
import passwordValidator from "../../util/passwordValidator";
import emailValidator from "../../util/emailValidator";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index_number: "",
      year: "",
      intake_id: "",
      intake_name: "",
      academic_year_id: "",
      level_id: "",
      level_name: "",
      academic_year: "",
      indexno: "",
      levels: [],
      necta: [],
      countries: [],
      regions: [],
      tzregion: "",
      districts: [],
      tzdistrict: "",
      region: "",
      district: "",
      phone: "",
      username: "",
      password: "",
      citizenship: "",
      dob: "",
      date: "",
      dayofBirth: "",
      month: "",
      yearofBirthday: "",
      confirmpassword: "",
      display_details: [],
      account_existence_status: false,
      valid_olevel_information: "",
      restricted_indexno: false,
      account_form: false,
      clearable: true,
      residence: "",
      showRegion: "",
      showDistrict: "",
      loading: "",
      district_loadig: "",
      fields: {},
      errors: {},
      serverError: "",
      applicationProgramme: null,
      level: "",
      country: "",
      hide: true,
    };
  }

  componentDidMount() {
    this.getCurrentIntake();
    this.getCountries();
    this.getRegions();
  }

  hideSwitch = (ev) => {
    this.setState({ hide: !this.state.hide });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });
      let payLoad;
      payLoad = {
        indexno: this.state.index_number,
        year: this.state.year,
      };

      if (this.state.level_id == 3) {
        api
          .post(params.CHECK_APPLICANT_STATUS_TCU, payLoad)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                api
                  .post(params.CHECKING_ACCOUNT_EXISTENCE, payLoad)
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.stsCode === "0000") {
                        this.setState({
                          restricted_indexno: false,
                          display_details: res.data.data.particulars,
                          necta: res.data.data,
                          intake_id: this.state.intake_id,
                          level_id: this.state.level_id,
                          academic_year_id: this.state.academic_year_id,
                          valid_olevel_information: true,
                          loading: false,
                          account_form: false,
                          account_existence_status: false,
                          account_created: " ",
                        });
                      }
                      if (res.data.stsCode === "0001") {
                        // this.setState({
                        //   valid_olevel_information: "",
                        //   restricted_indexno: true,
                        //   loading: false,
                        // });
                        alert(res.data.stsMessage);
                        window.location.reload(false);
                      }
                      if (res.data.stsCode === 463) {
                        // this.setState({
                        //   valid_olevel_information: "",
                        //   restricted_indexno: true,
                        //   loading: false,
                        // });
                        alert(res.data.stsMessage);
                        window.location.reload(false);
                      }
                      ///
                    } else {
                      this.setState({ account_existence_status: true });
                    }
                  })
                  .catch((error) => {
                    if (error) {
                      //console.log(error);
                    }
                  });
              } else if (res.data.stsCode === "0001") {
                alert(
                  res.data.stsMessage
                  //  +
                  //   " " +
                  //   ": CLEAR YOUR TCU STATUS FIRST BEFORE CONTINUING WITH APPLICATION"
                );
                window.location.reload(false);
              }
            }
          })
          .catch((error) => {
            if (error) {
            }
          });
      } else {
        api
          .post(params.CHECKING_ACCOUNT_EXISTENCE, payLoad)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                this.setState({
                  restricted_indexno: false,
                  display_details: res.data.data.particulars,
                  necta: res.data.data,
                  intake_id: this.state.intake_id,
                  level_id: this.state.level_id,
                  academic_year_id: this.state.academic_year_id,
                  valid_olevel_information: true,
                  loading: false,
                  account_form: false,
                  account_existence_status: false,
                  account_created: " ",
                });
              }
              if (res.data.stsCode === "0001") {
                // this.setState({
                //   valid_olevel_information: "",
                //   restricted_indexno: true,
                //   loading: false,
                // });
                alert(res.data.stsMessage);
                window.location.reload(false);
              }
              if (res.data.stsCode === 463) {
                // this.setState({
                //   valid_olevel_information: "",
                //   restricted_indexno: true,
                //   loading: false,
                // });
                alert(res.data.stsMessage);
                window.location.reload(false);
              }
              ///
            } else {
              this.setState({ account_existence_status: true });
            }
          })
          .catch((error) => {
            if (error) {
              //console.log(error);
            }
          });
      }
    }
  };

  //
  submissionValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialChar = /[^A-Za-z0-9]/g;

    if (
      !fields["dayofBirth"] ||
      typeof fields["dayofBirth"] === undefined ||
      !fields["month"] ||
      typeof fields["month"] === undefined ||
      !fields["yearofBirthday"] ||
      typeof fields["yearofBirthday"] === undefined
    ) {
      formIsValid = false;
      errors["birthday"] = "* day, month or year of birth is empty.";
    }
    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    }
    if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["citizenship"] || typeof fields["citizenship"] === undefined) {
      formIsValid = false;
      errors["citizenship"] = "* Please select your.";
    }
    if (!fields["residence"] || typeof fields["residence"] === undefined) {
      formIsValid = false;
      errors["residence"] = "* Please select country of residency.";
    }
    if (fields["residence"] === "Tanzania") {
      if (!fields["tzregion"] || typeof fields["tzregion"] === undefined) {
        formIsValid = false;
        errors["tzregion"] = "* Please select region.";
      }
      if (!fields["tzdistrict"] || typeof fields["tzdistrict"] === undefined) {
        formIsValid = false;
        errors["tzdistrict"] = "* Please select district.";
      }
    }
    if (fields["residence"] !== "Tanzania") {
      if (!fields["region"] || typeof fields["region"] === undefined) {
        formIsValid = false;
        errors["region"] = "* Please fill the  region.";
      }
      if (!fields["district"] || typeof fields["district"] === undefined) {
        formIsValid = false;
        errors["district"] = "* Please fill the  district.";
      }
    }
    if (!fields["username"] || typeof fields["username"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Please fill the username.";
    }
    if (emailValidator(fields["username"]) === false) {
      formIsValid = false;
      errors["username"] = "* Email is invalid.";
    }
    if (!fields["password"] || typeof fields["password"] === undefined) {
      formIsValid = false;
      errors["password"] = "* Please fill the password.";
    } else {
      //password validator
      if (!lowerCase.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!upperCase.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!numbers.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!specialChar.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (fields["password"].length < 8) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      }
    }
    // if (passwordValidator(fields["password"]) === false) {
    //   formIsValid = false;
    //   errors["password"] = "* Password is invalid or is less than 8 characters";
    // }

    if (!fields["pass"] || typeof fields["pass"] === undefined) {
      formIsValid = false;
      errors["pass"] = "* Confirm the password.";
    }
    if (fields["password"] !== fields["pass"]) {
      formIsValid = false;
      errors["pass"] = "* Passwords does not match.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  /// applicant account creation method from form click event

  createAccount = async (e) => {
    e.preventDefault();
    if (this.submissionValidation()) {
      this.setState({ loading: true });
      let account_details = {
        email: this.state.username,
        password: await hashPasscode(this.state.password),
        cell_phone: this.state.phone,
        dob:
          this.state.yearofBirthday.toString() +
          "-" +
          this.state.month.toString() +
          "-" +
          this.state.dayofBirth.toString(),
        sitting_year: this.state.year,
        citizenship: this.state.citizenship,
        country: this.state.residence,
        // region: this.state.region,
        region:
          this.state.region !== "" ? this.state.region : this.state.tzregion,
        district:
          this.state.district !== ""
            ? this.state.district
            : this.state.tzdistrict,
        level: this.state.level_id,
      };
      let payLoad = {
        account_details: account_details,
        necta: this.state.necta,
      };
      const indexNo = this.state.index_number.replace(/\//g, "-");
      const indexNoTrimmedSpace = indexNo.replace(/\s+/g, "");
      // var indexNoYear = this.state.index_number + "-" + this.state.year;
      // var indexNoYear = indexNo + "-" + this.state.year;
      var indexNoYear = indexNoTrimmedSpace + "-" + this.state.year;
      await api
        .post(params.USER_ACCOUNT + indexNoYear, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                account_created: true,
                account_form: false,
                loading: false,
                account_existence_status: "",
              });
              //  setTimeout(this.props.history.push("/"), 6000);
            } else if (res.data.stsCode === "0001") {
              alert(res.data.stsMessage);
              window.location.reload();
            }
            // else {
            //   this.setState({
            //     account_created: false,
            //     account_form: true,
            //     account_existence_status: true,
            //   });
            //   console.log(res.data.stsCode);
            // }
          }
        })
        .catch((error) => {
          if (error) {
            // console.log(error);
          }
        });
    }
  };

  getCurrentIntake = async () => {
    await api
      .get(params.CURRENT_INTAKE)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              academic_year_id: res.data.data.academicYear,
              academic_year: res.data.data.academicYear,
              intake_id: res.data.data.intake.id,
              intake_name: res.data.data.intake.intake,
              levels: res.data.data.awards,
              applicationProgramme: 1,
            });
          }
          if (res.data.stsCode === "0001") {
            this.setState({ applicationProgramme: null });
          }
        } else {
          this.setState({ serverError: true });
        }
        // console.log(res.data.stsCode);
      })
      .catch((error) => {
        if (error) {
          this.setState({ serverError: true });
        }
      });
  };
  getCountries = async () => {
    try {
      await api
        .get(params.COUNTRY_LIST)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                countries: res.data.data,
              });
            }
            if (res.data.stsCode === "0001") {
              this.setState({ applicationProgramme: null });
            }
          } else {
            this.setState({ serverError: true });
          }
        })
        .catch((err) => {
          this.setState({ serverError: true });
        });
    } catch (error) {
      this.setState({ serverError: true });
    }
  };

  getRegions = async () => {
    try {
      api
        .get(params.REGION_LIST)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                regions: res.data.data,
              });
            }
            if (res.data.stsCode === "0001") {
              this.setState({ applicationProgramme: null });
            }
          } else {
            this.setState({ serverError: true });
          }
        })
        .catch((err) => {
          this.setState({ serverError: true });
        });
    } catch (error) {
      this.setState({ serverError: true });
    }
  };

  getDistrict = async (id) => {
    this.setState({ district_loadig: true });
    try {
      await api
        .get(params.DISTRICT_LIST + "/" + id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                districts: res.data.data,
                district_loadig: false,
              });
            }
          } else {
            this.setState({});
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  handleInputStateEmail = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value.toLowerCase() });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });

    if (e.target.name === "formDecision") {
      e.target.value === "1"
        ? this.setState({ account_form: true, valid_olevel_information: true })
        : this.setState(signUpState);
    }
    if (e.target.name === "residence") {
      if (e.target.value === "") {
        this.setState({ showRegion: "" });
      }
      if (e.target.value === "Tanzania") {
        this.setState({ showRegion: true });
      }
      if (e.target.value !== "Tanzania" && e.target.value !== "") {
        this.setState({ showRegion: false });
      }
    }
    if (e.target.name === "tzregion") {
      this.setState({ showDistrict: true });
      if (e.target.value === "") {
        this.setState({ district_loadig: false });
      }
      if (e.target.value !== "") {
        this.setState({ districts: [] });
        const id = e.target.value.split(",");
        this.getDistrict(parseInt(id[0]));
        this.setState({ region: id[1] });
      }
    }
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["level_id"] || typeof fields["level_id"] === undefined) {
      formIsValid = false;
      errors["level_id"] = "* Please select level of application.";
    }
    if (
      !fields["index_number"] ||
      typeof fields["index_number"] === undefined
    ) {
      formIsValid = false;
      errors["index_number"] = "* Please fill the index number.";
    }
    if (!fields["year"] || typeof fields["year"] === undefined) {
      formIsValid = false;
      errors["year"] = "* Please select completion year.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  render() {
    let maxOffset = 40;
    let thisYear = new Date().getFullYear();
    let allYears = [];
    let yearsofBirth = [];

    for (let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x);
    }

    //console.log(thisYear - 10);
    let start = thisYear - 14; // initiate year
    for (let x = start; x > 1970; x--) {
      yearsofBirth.push(x);
    }

    const yearList = allYears.map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });

    const { hide } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-sm-1"> &nbsp;</div>
          <div className="col-sm-10">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Applicant Account Creation
                </div>
                <div className="float-right">
                  <button
                    className="backbutton"
                    onClick={() => this.props.history.push("/")}
                  >
                    {" "}
                    <i className="ti-angle-left"></i>
                    {this.state.account_created === true ? (
                      <>Go to Login Page</>
                    ) : (
                      <>Back</>
                    )}
                  </button>
                </div>
              </div>
              <div className="card-block">
                <div className="block">
                  {this.state.serverError === true ||
                  this.state.applicationProgramme === null ? (
                    // <div className="row">
                    //   <div className="col-sm-1"></div>
                    //   <div className="col-sm-10">
                    //     <div className="alert alert-warning">
                    //       <strong>Warning!!</strong> The application is
                    //       currently closed or the system is under maintainance.
                    //       Please contact the system administrator through :-{" "}
                    //       <br></br>
                    //       <font
                    //         style={{
                    //           color: "darkblue",
                    //           fontStyle: "italic",
                    //           paddingTop: "10px",
                    //         }}
                    //       >
                    //         admissions@tia.ac.tz {" , "} Help Desk: 0677 777 746
                    //         / 0625 777 744
                    //       </font>
                    //     </div>
                    //   </div>
                    //   <div className="col-sm-1"></div>
                    // </div>
                    <div className="row">
                      <div className="col-sm-4"></div>
                      <div className="col-sm-2">
                        <ReactLoading type="cylon" color="#000" />
                      </div>
                      <div className="col-sm-4"></div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="align-items-center account-content"></div>
                      </div>
                      {this.state.loading === true ? (
                        <div className="row">
                          <div className="col-sm-4"></div>
                          <div className="col-sm-2">
                            <ReactLoading type="cylon" color="#000" />
                          </div>
                          <div className="col-sm-4"></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {this.state.account_created === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-success">
                              <strong>Congratulation!!</strong> You have created
                              application account. Click the{" "}
                              <i>
                                <span className="w3-badge w3-green">
                                  'Go to Login Page'
                                </span>
                              </i>{" "}
                              button above to login into your account. and
                              password
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {this.state.account_created === false ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div class="alert alert-warning">
                              <strong>Warning!!</strong> You failed to create
                              application account please check your details and
                              resubmit or contact system administrator
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {this.state.account_existence_status === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-danger">
                              <strong>Warning!</strong> You have a pending{" "}
                              {this.state.level_name}
                              application. Please click{" "}
                              <Link to={"/"}> here</Link> to continue with the
                              application
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : this.state.valid_olevel_information === "" &&
                        this.state.account_existence_status === false ? (
                        <div>
                          {this.state.restricted_indexno === true ? (
                            <div className="row">
                              <div className="col-sm-1"></div>
                              <div className="col-sm-10">
                                <div className="alert alert-warning">
                                  <strong>Warning!</strong> You index number is
                                  invalid or restricted please correct and
                                  resubmit or communicate with necta
                                </div>
                              </div>
                              <div className="col-sm-1"></div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <form onSubmit={this.onSubmit}>
                            <div className="row progress-bar-content">
                              <div className="col-sm-1"></div>
                              <div className="col-sm-10">
                                <div className="form-group">
                                  <label className="label-class">
                                    Level of Application
                                    <font className="label-comment">*</font>
                                  </label>
                                  <select
                                    type="number"
                                    name="level_id"
                                    value={this.state.name}
                                    onChange={this.handleInputState}
                                    className="selection-box  input-placeholder"
                                  >
                                    <option key="">
                                      {" "}
                                      Select level of Application
                                    </option>
                                    {this.state.levels.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.award +
                                          " - " +
                                          "Application Deadline" +
                                          " : " +
                                          option.applDeadline}
                                      </option>
                                    ))}
                                  </select>
                                  {this.state.errors.level_id ? (
                                    <div className="errorMsg">
                                      {this.state.errors.level_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="row progress-bar-content">
                              <div className="col-sm-1"></div>
                              <div className="col-sm-6">
                                <label className="label-class">
                                  O Level Index Number/ NECTA Equivalence Number
                                  (First Sitting Index Number)
                                  <font className="label-comment">*</font>
                                </label>

                                <input
                                  type="text"
                                  name="index_number"
                                  className="form-control input-placeholder"
                                  // className={
                                  //   isError.index_number.length > 0
                                  //     ? "is-invalid form-control input-placeholder"
                                  //     : "form-control input-placeholder"
                                  // }
                                  value={this.state.name}
                                  onChange={this.handleInputState}
                                  placeholder="E.g. SXXXX-XXXX  or EQXXXXXXXXXXX"
                                ></input>
                                {this.state.errors.index_number ? (
                                  <div className="errorMsg">
                                    {this.state.errors.index_number}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-4">
                                <label className="label-class">
                                  Completion Year{" "}
                                  <font className="label-comment">*</font>
                                </label>
                                <select
                                  name="year"
                                  className="selection-box  input-placeholder"
                                  value={this.state.name}
                                  onChange={this.handleInputState}
                                >
                                  <option key="">
                                    {" "}
                                    Selection Completion Year
                                  </option>
                                  {yearList}
                                </select>
                                {this.state.errors.year ? (
                                  <div className="errorMsg">
                                    {this.state.errors.year}
                                  </div>
                                ) : null}
                                <div className="col-sm-2"></div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6"></div>
                              <div className="col-sm-2 ">
                                <button
                                  type="submit"
                                  className="btn btn-round  btn-sm btn-primary verification-button"
                                >
                                  Next <i className="ti-arrow-circle-right"></i>
                                </button>
                              </div>
                              <div className="col-sm-2"></div>
                            </div>
                          </form>
                        </div>
                      ) : this.state.valid_olevel_information === true &&
                        this.state.account_existence_status === false ? (
                        <div className="row">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-7">
                            <p>
                              {" "}
                              <b>STUDENT DETAILS</b> :{" "}
                              <font className="text-danger">
                                PLEASE CONFIRM YOUR INFORMATION BEFORE YOU
                                PROCEED
                              </font>
                            </p>

                            <div className="form-group row alert applicant-necta-detail">
                              <div className="student-detail">
                                <label className="form-control-label">
                                  Index Number : {"    "}
                                </label>
                                <strong className="index mr-3">
                                  {this.state.display_details.index_number}
                                </strong>
                                <label className="yearl form-control-label">
                                  Year :{" "}
                                </label>
                                <strong className="year mr-3">
                                  {this.state.year}
                                </strong>
                                <label className="form-control-label">
                                  Name:{" "}
                                </label>
                                <strong className="sname">
                                  {"   "}
                                  {this.state.display_details.last_name +
                                    "," +
                                    " " +
                                    this.state.display_details.first_name +
                                    " " +
                                    this.state.display_details.middle_name}
                                </strong>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">
                                Are the above Information yours?
                              </label>
                              <select
                                className="selection-box  input-placeholder"
                                onChange={this.handleInputState}
                                name="formDecision"
                              >
                                <option key="1">
                                  {" "}
                                  Please select the answer
                                </option>
                                <option key="2" value="1">
                                  {" "}
                                  Yes
                                </option>
                                <option key="3" value="2">
                                  {" "}
                                  No
                                </option>
                              </select>
                            </div>
                            {this.state.account_form === true ? (
                              // account creation form
                              <form onSubmit={this.createAccount}>
                                <fieldset className="scheduler-border">
                                  <legend className="scheduler-border">
                                    Date of Birth and Address
                                  </legend>
                                  <div className="form-group">
                                    <label className="label-class">
                                      Date of Birth{" "}
                                      <font className="label-comment">*</font>
                                    </label>
                                    <br></br>
                                    <select
                                      type="text"
                                      name="dayofBirth"
                                      value={this.state.dayofBirth}
                                      onChange={this.handleInputState}
                                      className="custom-select custom-select-md selectpicker col-sm-3"
                                      aria-readonly="true"
                                    >
                                      <option key=""> Select Day </option>
                                      {dayList.days.map((option) => (
                                        <option
                                          key={option.day}
                                          value={option.day}
                                        >
                                          {option.day}
                                        </option>
                                      ))}
                                    </select>
                                    &nbsp;
                                    <select
                                      type="text"
                                      name="month"
                                      value={this.state.month}
                                      onChange={this.handleInputState}
                                      className="custom-select custom-select-md selectpicker col-sm-3"
                                      aria-readonly="true"
                                    >
                                      <option key=""> Select Month </option>
                                      {monthsList.months.map((option) => (
                                        <option
                                          key={option.number}
                                          value={option.number}
                                        >
                                          {option.month}
                                        </option>
                                      ))}
                                    </select>
                                    &nbsp;
                                    <select
                                      type="text"
                                      name="yearofBirthday"
                                      value={this.state.yearofBirthday}
                                      onChange={this.handleInputState}
                                      className="custom-select custom-select-md selectpicker col-sm-3"
                                      aria-readonly="true"
                                    >
                                      <option key=""> Select Year </option>
                                      {yearsofBirth.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                    {this.state.errors.birthday ? (
                                      <div className="errorMsg">
                                        {this.state.errors.birthday}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="form-group">
                                    <label className="label-class">
                                      Phone
                                      <font className="label-comment">*</font>
                                    </label>
                                    <input
                                      name="phone"
                                      type="phone"
                                      value={this.state.name}
                                      onChange={this.handleInputState}
                                      className="form-control"
                                      placeholder="Phone Number (Mobile Phone Number)"
                                    />
                                    {this.state.errors.phone ? (
                                      <div className="errorMsg">
                                        {this.state.errors.phone}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <label className="label-class">
                                          Citizeship
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        <select
                                          type="text"
                                          name="citizenship"
                                          value={this.state.citizenship}
                                          onChange={this.handleInputState}
                                          className="custom-select custom-select-md"
                                          aria-readonly="true"
                                        >
                                          <option key="" value="">
                                            -- Select citizenship --{" "}
                                          </option>
                                          {this.state.countries.map(
                                            (options) => (
                                              <option
                                                key={options.id}
                                                value={options.citizenship}
                                              >
                                                {options.citizenship}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {this.state.errors.citizenship ? (
                                          <div className="errorMsg">
                                            {this.state.errors.citizenship}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-sm-6">
                                        <label className="label-class">
                                          Country of Residence
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        <select
                                          type="text"
                                          name="residence"
                                          value={this.state.residence}
                                          onChange={this.handleInputState}
                                          className="custom-select custom-select-md"
                                          aria-readonly="true"
                                        >
                                          <option key="" value="">
                                            -- Select country of residence --{" "}
                                          </option>
                                          {this.state.countries.map(
                                            (options) => (
                                              <option
                                                key={options.id}
                                                value={options.country}
                                              >
                                                {options.country}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {this.state.errors.residence ? (
                                          <div className="errorMsg">
                                            {this.state.errors.residence}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.showRegion === true ? (
                                    <>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-sm-6">
                                            <label className="label-class">
                                              Region
                                              <font className="label-comment">
                                                *
                                              </font>
                                            </label>
                                            <select
                                              type="text"
                                              name="tzregion"
                                              value={this.state.tzregion}
                                              onChange={this.handleInputState}
                                              className="custom-select custom-select-md"
                                              aria-readonly="true"
                                            >
                                              <option key="" value="">
                                                -- Select Region --{" "}
                                              </option>
                                              {this.state.regions.map(
                                                (options) => (
                                                  <option
                                                    key={options.id}
                                                    value={
                                                      options.id +
                                                      "," +
                                                      toTitleconverttoTitle(
                                                        options.region
                                                      )
                                                    }
                                                  >
                                                    {toTitleconverttoTitle(
                                                      options.region
                                                    )}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            {this.state.errors.tzregion ? (
                                              <div className="errorMsg">
                                                {this.state.errors.tzregion}
                                              </div>
                                            ) : null}
                                          </div>

                                          {this.state.showDistrict === true ? (
                                            <div className="col-sm-6">
                                              {this.state.district_loadig ===
                                              true ? (
                                                <ReactLoading
                                                  type="cylon"
                                                  color="#000"
                                                />
                                              ) : (
                                                <>
                                                  <label className="label-class">
                                                    District
                                                    <font className="label-comment">
                                                      *
                                                    </font>
                                                  </label>
                                                  <select
                                                    type="text"
                                                    name="tzdistrict"
                                                    value={
                                                      this.state.tzdistrict
                                                    }
                                                    onChange={
                                                      this.handleInputState
                                                    }
                                                    className="custom-select custom-select-md"
                                                    aria-readonly="true"
                                                  >
                                                    <option key="" value="">
                                                      -- Select District --{" "}
                                                    </option>
                                                    {this.state.districts.map(
                                                      (options) => (
                                                        <option
                                                          key={options.id}
                                                          value={toTitleconverttoTitle(
                                                            options.district
                                                          )}
                                                        >
                                                          {toTitleconverttoTitle(
                                                            options.district
                                                          )}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  {this.state.errors
                                                    .tzdistrict ? (
                                                    <div className="errorMsg">
                                                      {
                                                        this.state.errors
                                                          .tzdistrict
                                                      }
                                                    </div>
                                                  ) : null}
                                                </>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {this.state.showRegion === false ? (
                                        <>
                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-sm-6">
                                                <label className="label-class">
                                                  Region
                                                  <font className="label-comment">
                                                    *
                                                  </font>
                                                </label>
                                                <input
                                                  type="text"
                                                  name="region"
                                                  value={this.state.region}
                                                  onChange={
                                                    this.handleInputState
                                                  }
                                                  className="form-control"
                                                  placeholder="Region"
                                                />
                                                {this.state.errors.region ? (
                                                  <div className="errorMsg">
                                                    {this.state.errors.region}
                                                  </div>
                                                ) : null}
                                              </div>

                                              <div className="col-sm-6">
                                                <label className="label-class">
                                                  District
                                                  <font className="label-comment">
                                                    *
                                                  </font>
                                                </label>
                                                <input
                                                  type="text"
                                                  name="district"
                                                  value={this.state.district}
                                                  onChange={
                                                    this.handleInputState
                                                  }
                                                  className="form-control"
                                                  placeholder="District"
                                                />
                                                {this.state.errors.district ? (
                                                  <div className="errorMsg">
                                                    {this.state.errors.district}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                </fieldset>

                                {/*End of Address Information*/}

                                <fieldset className="scheduler-border">
                                  <legend className="scheduler-border">
                                    Login Information
                                  </legend>

                                  <div className="form-group">
                                    <label>
                                      {" "}
                                      Username (Must be an email address)*{" "}
                                    </label>
                                    <input
                                      type="email"
                                      name="username"
                                      value={this.state.username}
                                      onChange={this.handleInputStateEmail}
                                      className="form-control"
                                      placeholder="Email"
                                    />
                                    {this.state.errors.username ? (
                                      <div className="errorMsg">
                                        {this.state.errors.username}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="form-group">
                                    <label> Password * </label>
                                    <input
                                      name="password"
                                      // type="password"
                                      type={hide ? "password" : "text"}
                                      value={this.state.password}
                                      onChange={this.handleInputState}
                                      className="form-control"
                                      placeholder="Password"
                                    />
                                    <span
                                      className="password__show"
                                      onClick={this.hideSwitch}
                                      style={{
                                        position: "absolute",
                                        right: 20,
                                      }}
                                    >
                                      {hide ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                    {this.state.errors.password ? (
                                      <div className="errorMsg">
                                        {this.state.errors.password}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="form-group">
                                    <label> Confirm Password * </label>
                                    <input
                                      placeholder="Confirm Password"
                                      className="form-control"
                                      name="pass"
                                      // type="password"
                                      type={hide ? "password" : "text"}
                                      value={this.state.pass}
                                      onChange={this.handleInputState}
                                    />
                                    <span
                                      className="password__show"
                                      onClick={this.hideSwitch}
                                      style={{
                                        position: "absolute",
                                        right: 20,
                                      }}
                                    >
                                      {hide ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                    {this.state.errors.pass ? (
                                      <div className="errorMsg">
                                        {this.state.errors.pass}
                                      </div>
                                    ) : null}
                                  </div>
                                </fieldset>

                                <div className="row">
                                  <div className="col-sm-5"></div>
                                  <div className="col-sm-2 ">
                                    <button className="btn btn-round  btn-sm btn-primary verification-button">
                                      Finish{" "}
                                      <i className="ti-arrow-circle-right"></i>
                                    </button>
                                  </div>
                                  <div className="col-sm-2"></div>
                                </div>
                              </form>
                            ) : (
                              // end of account creation form

                              <div></div>
                            )}
                          </div>
                          <div className="col-sm-2"></div>
                        </div>
                      ) : (
                        <div>{}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-1"> &nbsp;</div>
        </div>
      </div>
    );
  }
}
export default SignUp;
